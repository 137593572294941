@import "variables";

@keyframes showHeader {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	border-bottom: 1px solid #fff;
	color: #fff;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 12%, rgba(0, 0, 0, 0.5) 49%, rgba(0, 0, 0, 0) 100%);
	height: 140px;
	z-index: 999;
	transition: transform 200ms linear;

	&.header-hidden {
		transform: translateY(-141px);
	}

	.header-content {
		display: flex;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		transition: all 200ms ease-in-out;
	}

	h1.logo {
		display: flex;
		align-items: center;
		word-break: keep-all;
		white-space: nowrap;
		font-size: 3rem;
		line-height: 1;
		user-select: none;
		cursor: default;

		> img {
			width: 2em;
			height: 2em;
		}

		.logo-text {
			margin-left: 0.25em;
			text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.19);

			> div {
				display: flex;
				align-items: flex-end;
				span {
					display: block;
					line-height: 1em;

					&:first-of-type {
						font-weight: 900;
						margin-right: 0.25em;
					}

					&:nth-of-type(2) {
						font-weight: 400;
						margin-right: 0.125em;
					}

					&:last-of-type {
						font-weight: 100;
						font-size: 0.333em;
						line-height: 0.333em;
						margin-bottom: 10px;
					}
				}
			}

			> span {
				display: block;
				font-weight: 100;
				font-size: 0.333em;
				line-height: 0.333em;
				margin-top: 0.333em;
			}
		}
	}

	nav {
		line-height: 110%;
		.hamburger {
			display: none;
		}
		.nav-wrapper {
			display: flex;
			align-items: flex-end;
			flex-direction: column;
			gap: 16px;
			.language-switcher {
				.list-divided {
					li {
						font-weight: 100;
					}
				}
			}
			.main-nav {
				.list-divided {
					li {
						font-weight: 700;
						a {
							color: #fff;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	@media (max-width: $medium) {
		height: 80px;
		background: rgba(0, 0, 0, 0.5);

		h1.logo {
			font-size: 2rem;
			z-index: 999;
			position: relative;

			.logo-text {
				> div {
					span {
						&:last-of-type {
							font-weight: 200;
							margin-bottom: 7px;
						}
					}
				}

				> span {
					font-weight: 200;
				}
			}
		}

		nav {
			.nav-wrapper {
				position: fixed;
				top: 81px;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, 0.8);
				align-items: center;
				justify-content: center;
				transform: translateX(100%);
				transition: transform 0.3s ease-in-out;
				.main-nav {
					width: 100%;
					.list-divided {
						flex-direction: column;
						font-size: 2rem;
						margin-left: 10vw;
						margin-right: 10vw;
						li {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 5rem;
							line-height: 120%;
							text-align: center;
							&:not(:last-of-type) {
								border-bottom: 1px solid rgba(255,255,255,0.3);
							}
							&::after {
								display: none;
							}
							a {
								&::after {
									display: none;
								}
							}
						}
					}
				}
			}
			.hamburger {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				outline: none;
				background: transparent;
				z-index: 999;
				border: none;

				.line {
					width: 100%;
					height: 2px;
					background: #fff;
					border-radius: 2px;
					transition: all 0.3s ease-in-out;

					&::before,
					&::after {
						content: '';
						position: absolute;
						left: 0;
						width: 100%;
						height: 2px;
						background: #fff;
						border-radius: 2px;
						transition: all 0.3s ease-in-out;
					}

					&::before {
						transform: translateY(-10px);
					}

					&::after {
						transform: translateY(10px);
					}
				}
			}
		}

		&.nav-is-open {

			nav {
				.hamburger {
					.line {
						transform: translateX(-50px);
						background: transparent;
						box-shadow: none;

						&::before {
							transform: rotate(45deg) translate(35px, -35px);
						}

						&::after {
							transform: rotate(-45deg) translate(35px, 35px);
						}
					}
				}
				.nav-wrapper {
					transform: translateX(0);
				}
			}
		}
	}
}
