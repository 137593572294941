@import "variables";

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: smooth;
}

body {
	background: #fff;
	color: $color-primary;
	font-size: 1rem;
	font-family: 'Poppins', sans-serif;
	min-height: 100%;

	@media (max-width: $medium) {
		font-size: 0.875rem;
	}
}

button,
input {
	font-family: 'Poppins', sans-serif;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding-left: 1rem;
	padding-right: 1rem;
}

h2 {
	font-size: 3rem;
	font-weight: 900;
	text-align: center;
	margin: 2rem 0;

	@media (max-width: $small) {
		margin: 1.5rem 0;
		font-size: 2.5rem;
	}
}

h3 {
	font-size: 1rem;
	line-height: 1.1em;
	font-weight: 900;
	margin-bottom: 1em;
}

a {
	position: relative;
	color: #000;
	text-decoration: none;

	&::after {
		position: absolute;
		content: '';
		left: 0;
		right: 100%;
		bottom: 0;
		height: 1px;
		background-color: #000;
		transition: right 250ms ease-in-out;
	}
	&:hover,
	&:focus-visible {
		&::after {
			right: 0;
		}
	}

	&.link-light {
		color: #fff;

		&::after {
			background-color: #fff;
		}
	}
}

.btn-link {
	outline: none;
	border: none;
	padding: none;
	background: transparent;
	cursor: pointer;
	color: #fff;
	font-weight: 100;
	font-size: 1rem;
	position: relative;

	&::after {
		position: absolute;
		content: '';
		left: 0;
		right: 100%;
		bottom: 0;
		height: 1px;
		background-color: #fff;
		transition: right 250ms ease-in-out;
	}

	&:hover,
	&:focus-visible {
		&::after {
			right: 0;
		}
	}
}

.section-base {
	border-bottom: 1px solid $color-primary;
	font-size: 1.125rem;

	@media (max-width: $small) {
		font-size: 1rem;
	}
}

// library
@import "toast";

// components
@import "quote";
@import "list_divided";

// sections
@import "hero";
@import "header";
@import "about";
@import "gallery";
@import "contact";
@import "contact_divider";
@import "footer";
