@import "variables";

.hero {
	position: relative;
	width: 100%;
	min-height: 600px;
	height: 100vh;
	max-height: 1200px;
	background: #000 url('../assets/hero-min.jpg') bottom center no-repeat;
	background-size: cover;

	&::before {
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		top: 0;
		height: 200px;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 25%, rgba(0, 0, 0, 0) 100%);
	}
}

.hero-content {
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	.quote {
		margin-bottom: 1.5rem;
	}

	@media (max-width: $medium) {
		align-items: center;
		justify-content: center;
		.quote {
			text-align: center;
			text-shadow: 1px 2px 3px rgba(0,0,0,0.52);
			color: #fff;
			max-width: 550px;
			p {
				text-align: center;
			}
		}
	}
}
